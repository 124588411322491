.navbar {
  box-shadow: 0 3px 30px #00000029;
  padding: 0;
  display: flex;
  background-color: $white;
  z-index: 1;

  @include media-breakpoint-up(xl) {
    .container:first-child {
      margin-right: 20rem;
    }
    .container:last-child {
      margin-left: 20rem;
    }
  }

  .navbar-toggler {
    margin-top: 1rem;
  }

  .menu {
    display: block;
    margin-top: 1rem;

    @include media-breakpoint-up(lg) {
      display: inline-flex;

      li {
        padding-left: 1.5rem;
      }
    }



    a {
      color: $brand-primary;
      padding: .5rem $grid-gutter-width/2;
      display: block;

      &:hover, &.active, &.active-trail {
        background-color: $brand-primary;
        text-decoration: none;
        color: $white;
        border-radius: $border-radius;
      }

      &.active, &.active-trail {
        color: $white;
      }
    }
  }
}

.logo {
  img {
    height: 5rem;
  }
}
