.action-links {
  a {
    margin-right: 10px;
  }
}

html {
  &.overlay-open .navbar-fixed-top {
    z-index: 400;
  }
  &.js {
    // Collapsible fieldsets.
    fieldset.collapsed {
      border-left-width: 1px;
      border-right-width: 1px;
      height: auto;
    }
    // Throbbers.
    input.form-autocomplete {
      background-image: none;
    }
    // Autocomplete.
    input.form-autocomplete {
      background-image: none;
    }
    // Autocomplete (fallback throbber, no icon).
    .autocomplete-throbber {
      background-position: 100% 2px;
      background-repeat: no-repeat;
      display: inline-block;
      height: 15px;
      margin: 2px 0 0 2px;
      width: 15px;
    }
    .autocomplete-throbber.throbbing {
      background-position: 100% -18px;
    }
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h3, .h4, .h5, .h6 {
  margin-top: $headings-margin-top;
}

// Adjust z-index of core components.
#overlay-container,
.overlay-modal-background,
.overlay-element {
  z-index: 1500;
}
#toolbar {
  z-index: 1600;
}
// Adjust z-index of bootstrap modals
.modal {
  z-index: 1620;
}
.modal-dialog {
  z-index: 1630;
}
.ctools-modal-dialog .modal-body {
  width: 100% !important;
  overflow: auto;
}
.modal-backdrop {
  z-index: 1610;
}
table.sticky-header {
  z-index: 1;
}

// Element invisible fix
.element-invisible {
  margin: 0;
  padding: 0;
  width: 1px;
}

// Help region.
.region-help {
  > .glyphicon {
    font-size: $font-size-lg;
    float: left;
    margin: -0.05em 0.5em 0 0;
  }
  .block {
    overflow: hidden;
  }
}

// Search form.
form#search-block-form {
  margin: 0;
}
.navbar #block-search-form {
  float: none;
  margin: 5px 0 5px 5px;

  .input-group-btn {
    width: auto;
  }
}
.navbar-search .control-group {
  margin-bottom:0px;
}

// Action Links
ul.action-links {
  margin: map-get(map-get($spacers, 1), y) 0;
  padding: 0;
  li {
    display: inline;
    margin: 0;
    padding: 0 map-get(map-get($spacers, 1), y) 0 0;
  }
  .glyphicon {
    padding-right: 0.5em;
  }
}

// Error containers.
.error {
  color: $state-danger-text;
}
div.error,
table tr.error {
  background-color: $state-danger-bg;
  color: $state-danger-text;
}
.form-group.error,
.form-group.has-error {
  background: none;
  label, .control-label {
    color: $state-danger-text;
    font-weight: 600;
  }
  input, textarea, select, .uneditable-input {
    color: $input-color;
  }
  .help-block, .help-inline {
    color: $text-muted;
  }
}

// Lists
.nav-tabs {
  margin-bottom: 10px;
}
ul li.collapsed,
ul li.expanded,
ul li.leaf {
  list-style: none;
}
.tabs--secondary {
  margin: 0 0 10px;
}

// Submitted
.submitted {
  margin-bottom: 1em;
  font-style: italic;
  font-weight: normal;
  color: #777;
}

// Password strength/match.
.form-type-password-confirm {
  position: relative;
  label {
    display: block;
    .label {
      float: right;
    }
  }
  .password-help {
    padding-left: 2em;
    @include media-breakpoint-up(md) {
      border-left: 1px solid $table-border-color;
      left: percentage((6/12));
      margin-left: ($grid-gutter-width / 2);
      position: absolute;
    }
    @include media-breakpoint-up(lg) {
      left: percentage((4/12));
    }
  }
  .progress {
    background: transparent;
    -moz-border-radius: 0 0 5px 5px;
    -webkit-border-radius: 0 0 5px 5px;
    border-radius: 0 0 5px 5px;
    box-shadow: none;
    height: 4px;
    margin: -5px 0px 0;
  }
  .form-type-password {
    clear: left;
  }
  .form-control-feedback {
    right: 15px;
  }
  .help-block {
    clear: both;
  }
}

// Views AJAX pager.
ul.pagination li > a {
  &.progress-disabled {
    float: left;
  }
}

// Autocomplete and throbber
.form-autocomplete {
  .glyphicon {
    color: $gray-light;
    font-size: 120%;
    &.glyphicon-spin {
      color: $brand-primary;
    }
  }
  .input-group-addon {
    background-color: rgb(255, 255, 255);
  }
}

// AJAX "Progress bar".
.ajax-progress-bar {
  border-radius: 0 0 $border-radius $border-radius;
  border: 1px solid $input-group-addon-border-color;
  margin: -1px 0 0;
  padding: map-get(map-get($spacers, 1), y) map-get(map-get($spacers, 1), x);
  width: 100%;
  .progress {
    height: 8px;
    margin: 0;
  }
  .percentage,
  .message {
    color: $text-muted;
    font-size: $font-size-sm;
    line-height: 1em;
    margin: 5px 0 0;
    padding: 0;
  }
}

.glyphicon-spin {
  display: inline-block;
  -moz-animation: spin 1s infinite linear;
  -o-animation: spin 1s infinite linear;
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}
a .glyphicon-spin {
  display: inline-block;
  text-decoration: none;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

/**
 * Missing Bootstrap 2 tab styling.
 * @see http://stackoverflow.com/questions/18432577/stacked-tabs-in-bootstrap-3
 * @see http://bootply.com/74926
 */
.tabbable {
  margin-bottom: 20px;
}
.tabs-below, .tabs-left, .tabs-right {
  > .nav-tabs {
    border-bottom: 0;
    .summary {
      color: $nav-disabled-link-color;
      font-size: $font-size-sm;
    }
  }
}
.tab-pane > .panel-heading {
  display: none;
}
.tab-content > .active {
  display: block;
}

// Below.
.tabs-below {
  > .nav-tabs {
    border-top: 1px solid $nav-tabs-border-color;
    > li {
      margin-top: -1px;
      margin-bottom: 0;
      > a {
        border-radius: 0 0 $border-radius $border-radius;
        &:hover,
        &:focus {
          border-top-color: $nav-tabs-border-color;
          border-bottom-color: transparent;
        }
      }
    }
    > .active {
      > a,
      > a:hover,
      > a:focus {
        border-color: transparent $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-border-color;
      }
    }
  }
}

// Left and right tabs.
.tabs-left,
.tabs-right {
  > .nav-tabs {
    padding-bottom: 20px;
    width: 220px;
    > li {
      float: none;
      &:focus {
        outline: 0;
      }
      > a {
        margin-right: 0;
        margin-bottom: 3px;
        &:focus {
          outline: 0;
        }
      }
    }
  }
  > .tab-content {
    border-radius: 0 $border-radius $border-radius $border-radius;
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
    border: 1px solid $nav-tabs-border-color;
    overflow: hidden;
    padding: 10px 15px;
  }
}

// Left tabs.
.tabs-left {
  > .nav-tabs {
    float: left;
    margin-right: -1px;
    > li > a {
      border-radius: $border-radius 0 0 $border-radius;
      &:hover,
      &:focus {
        border-color: $nav-tabs-link-hover-border-color $nav-tabs-border-color $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color;
      }
    }
    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
      border-color: $nav-tabs-border-color transparent $nav-tabs-border-color $nav-tabs-border-color;
      box-shadow: -1px 1px 1px rgba(0,0,0,.05);
    }
  }
}

// Right tabs.
.tabs-right {
  > .nav-tabs {
    float: right;
    margin-left: -1px;
    > li > a {
      border-radius: 0 $border-radius $border-radius 0;
      &:hover,
      &:focus {
        border-color: $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color $nav-tabs-border-color;
        box-shadow: 1px 1px 1px rgba(0,0,0,.05);
      }
    }
    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
      border-color: $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-border-color transparent;
    }
  }
}

// Checkbox cell fix.
th.checkbox,
td.checkbox,
th.radio,
td.radio {
  display: table-cell;
}

// Views UI fixes.
.views-display-settings {
  .label {
    font-size: 100%;
    color:#666666;
  }
  .footer {
    padding:0;
    margin:4px 0 0 0;
  }
}
.views-exposed-form .views-exposed-widget .btn {
  margin-top: 1.8em;
}

// Radio and checkbox in table fixes
table {
  .radio input[type="radio"],
  .checkbox input[type="checkbox"] {
    max-width: inherit;
  }
}

// Exposed filters
.form-horizontal .form-group label {
  position: relative;
  min-height: 1px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px;
  padding-left:  ($grid-gutter-width / 2);
  padding-right: ($grid-gutter-width / 2);
  text-align: right;

  @include media-breakpoint-up(md) {
    float: left;
    width: percentage((2 / $grid-columns));
  }
}

// Treat all links inside alert as .alert-link
.alert a {
  font-weight: $alert-link-font-weight;
}
.alert-success {
  a, a:hover, a:focus {
    color: darken($alert-success-text, 10%);
  }
}
.alert-info {
  a, a:hover, a:focus {
    color: darken($alert-info-text, 10%);
  }
}
.alert-warning {
  a, a:hover, a:focus {
    color: darken($alert-warning-text, 10%);
  }
}
.alert-danger {
  a, a:hover, a:focus {
    color: darken($alert-danger-text, 10%);
  }
}

// Override image module.
div.image-style-new,
div.image-style-new div {
  display: block;
}
div.image-style-new div.input-group {
  display: table;
}

td.module,
.table-striped>tbody>tr:nth-child(odd)>td.module,
.table>tbody>tr>td.module {
  background: $table-border-color;
  font-weight: 700;
}

// Book module.
.book-toc {
  > .dropdown-menu {
    overflow: hidden;
    left: auto;
    right: 0;
    padding: 0 .5rem;

    &, > .dropdown-header {
      white-space: nowrap;
    }

    .dropdown-menu {
      box-shadow: none;
      border: 0;
      display: block;
      font-size: $font-size-sm;
      margin: 0;
      padding: 0;
      position: static;
      float: none;
      width: 100%;

      > li {
        padding-left: 20px;
      }
    }
  }

  > ul {
    padding-left: 0;
  }

  h3 {
    color: #fff;
    background-color: $brand-primary;
    padding: 1rem $grid-gutter-width/2;
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;
  }

  a.active {
    font-weight: bold;
  }
}

.pager {
  margin-bottom: 0;
  list-style: none;
  padding: 0;

  li {
    margin: .5rem 0;
    text-align: center;
  }
}

// Features module.
#features-filter .form-item.form-type-checkbox {
  padding-left: 20px;
}
fieldset.features-export-component {
  font-size: $font-size-sm;
  html.js #features-export-form &, & {
    margin: 0 0 ($line-height-base / 2);
  }
  .panel-heading {
    padding: 5px 10px;
    a.panel-title {
      font-size: $font-size-sm;
      font-weight: 500;
      margin: -5px -10px;
      padding: 5px 10px;
    }
  }
  .panel-body {
    padding: 0 10px;
  }
}
div.features-export-list {
  margin: -11px 0 10px;
  padding: 0 10px;
}

fieldset.features-export-component .component-select ,
div.features-export-list {
  .form-type-checkbox {
    line-height: 1em;
    margin: 5px 5px 5px 0 !important;
    min-height: 0;
    padding: 3px 3px 3px 25px!important;
    input[type=checkbox] {
      margin-top: 0;
    }
  }
}

// Navbar module.

body.navbar-is-fixed-top.navbar-administration.navbar-horizontal.navbar-tray-open .navbar-fixed-top {
  top: 79px;
}
body.navbar-is-fixed-top.navbar-administration .navbar-fixed-top {
  top: 39px;
}
/* Small devices (tablets, 768px and up) */
@include media-breakpoint-up(md) {
  body.navbar-is-fixed-top.navbar-administration.navbar-vertical.navbar-tray-open .navbar-fixed-top {
    left: 240px;
    left: 24rem;
  }
}
.navbar-administration #navbar-administration.navbar-oriented .navbar-bar{
  z-index: 1032;
}
.navbar-administration #navbar-administration .navbar-tray {
  z-index: 1031;
}
body.navbar-is-fixed-top.navbar-administration {
  padding-top: 103px!important;
}
body.navbar-is-fixed-top.navbar-administration.navbar-horizontal.navbar-tray-open {
  padding-top: 143px!important;
}
body.navbar-tray-open.navbar-vertical.navbar-fixed {
  margin-left: 240px;
  margin-left: 24rem;
}
#navbar-administration.navbar-oriented .navbar-tray-vertical {
  width: 24rem;
}

.card-header {
  outline: 1px solid rgba(0, 0, 0, 0.125);
  border: 0 none;
}

.main-container {
  padding-bottom: $spacer*3;
}

s {
  color: $gray;
}

.name {
  margin-top: $spacer*4;
  display: inline-block;
}

.field-body {
  margin: $spacer*2 0;
}

.help-block {
  font-size: $font-size-sm;
  margin-top: $spacer;
}

.card.file {
  img {
    width: 100%;
    height: auto;
  }
}

// TODO: Replace the use of id
#node-6513 {
  .card-title {
    display: none;
  }

  .item__bild-s {
    .card-block {
      display: none;
    }
  }

  .item__infobox {
    padding-top: 1rem;
  }
}

#node-99 {
  display: none;
}
