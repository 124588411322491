.front {
  .block-webform {
    background-color: $pink-light;
    border-bottom: 3px solid $brand-danger;
    margin-top: -2rem;
  }
}

.webform-client-form-6511 {
  .form-type-radio {
    img {
      width: 100%;
    }
  }
}

.form-checkbox, .form-radio {
  margin-right: $spacer;
}

.form-type-radio {
  img, a {
    display: block;
  }
  max-width: 100%;
}

.block-webform {
  .container {
    margin-bottom: 3.75rem;
  }

  h2 {
    color: $h3-color;
    font-weight: bold;
    margin-bottom: 1.5rem;
    padding-top: 9rem;
  }

  .row {
    margin-top: 1.5rem;

    .multicolumn-options-wrapper {
      display: flex;
    }
  }

  .card {
    background: none;
    border: none;
  }

  .card-header {
    background-color: transparent;
    border-bottom: 1px solid $gray-base;
    outline: none;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }

  .form-control {
    border-radius: $border-radius;
  }

  .form-type-checkbox {
    margin-bottom: 1rem;
  }

  a {
    color: $brand-danger;
  }

  .grippie {
    display: none;
  }

  .form-managed-file {
    button {
      display: none;
    }

    .form-file {
      height: 10rem;
    }
  }

  .form-actions {
    text-align: right;
  }

  .webform-component-fieldset {
    display: none;
  }

  .form-item-submitted-basisdaten-website-domain-exists-yes {
    margin-top: 3rem;
  }

  .webform-component--zusatzliche-bemerkungen-zu-ihrer-bestellung {
    margin-top: 2rem;
  }
}
