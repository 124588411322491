.view-display-id-block_1 {

  .view-content:first-child {
    background: $brand-danger;
    height: 150px;

    table {
      border-collapse: separate;
      border-spacing: 4rem 0;

      @include media-breakpoint-down(lg) {
        width: 100%;
        border-spacing: 1.5rem;
      }
    }

    tr {
      @include media-breakpoint-down(lg) {
        display: inline-flex;
        flex-wrap: wrap;
      }
    }
    tr:first-child {
      padding-left: 0;
    }
    td {
      position: relative;
      background-color: white;
      border-radius: 30px;
      box-shadow: 0 1px 15px $gray-light;
      padding: 1.8rem;
      top: -6rem;

      @include media-breakpoint-only(lg) {
        max-width: 20rem;
        margin: 3rem 5rem;
      }

      @include media-breakpoint-down(md) {
        max-width: 20rem;
        margin: 3rem 1rem;
      }

      .field-mainpic {
        margin-top: -5rem;
      }
    }

    h2 {
      color: $h3-color;
    }

    .field-text {
      padding-bottom: 1.8rem;
    }

    .btn-primary {
      padding: 0.5rem 6.5rem;

      @include media-breakpoint-down(lg) {
        padding: 0.5rem 5.5rem;
      }
    }
    .selected {
      background-color: $gray-light;
      color: $gray;
      border: none;
    }
  }

  .view-content:last-child {
    background: $pink;
    height: auto;
    padding-top: 25rem;

    td {
      background-color: transparent;
      border: none;
      box-shadow: none;
      padding-left: 0;
    }

    .field-hauptspalte {
      .file-image {
        border: none;
        margin-bottom: -15rem;
        z-index: 1;
      }
    }
  }
}

.view-id-themes {
  .view-display-id-block_2 {
    .view-content{
      @include media-breakpoint-down(lg) {
          padding-top: 70rem;
        }
      @include media-breakpoint-down(sm) {
        padding-top: 145rem;
      }

      tr {
        @include media-breakpoint-down(lg) {
          display: table-row;
        }
      }

      td {
        padding: 0;
      }
      .field-link {
        margin-bottom: 2rem;
        a {
          color: $brand-danger;
          border: 1px solid $brand-danger;
          background-color: transparent;
          border-radius: $border-radius;
          padding: .5rem;
        }
      }
      .card {
        background: none;
        border: none;
      }
    }
  }
}
