// Basic styling

body {
  background-color: $gray-lightest;
}

h1 {
  text-align: center;
  margin-bottom: 4rem;
}

h3 {
  color: $h3-color;
  font-weight: bold;
}

.btn-primary {
  border-radius: $btn-border-radius;
  font-size: $font-size-base;
}

// Highlighted Area

.highlighted {
  background-color: $brand-primary;
  max-height: 41rem;

  img {
    max-height: 31rem;

    @media (min-width: 1400px) {
      max-height: 41rem;
    }

    @include media-breakpoint-down(lg) {
      max-height: 23rem;
    }

    @include media-breakpoint-down(md) {
      max-height: 15rem;
    }
  }

  .tires {
    max-width: 100vw;
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    text-align: center;
  }
  .main-img {
    display: block;
    margin: 0 auto;
    position: relative;

    @include media-breakpoint-down(sm) {
      width: 100vw;
    }
  }

  .col-2,
  .col-3,
  .col-4 {
    display: none;
  }

  h2 {
    display: none;
  }

  .node-promoted {
    position: absolute;
    transform: translate(-18rem, -19rem);
  }

  .field-video {
    transform: translate(-18.5rem, -22rem);

    @include media-breakpoint-down(lg) {
      transform: translate(-2.5rem, -4rem);
    }
    @include media-breakpoint-down(md) {
      transform: translate(4.5rem, 4rem);
    }
  }

  video {
    width: 73rem;
    margin-top: -.15rem;

    @include media-breakpoint-down(lg) {
      width: 41rem;
    }
    @include media-breakpoint-down(md) {
      width: 27rem;
    }
  }

  .themes-featured-info {
    background-color: $brand-danger;
    color: white;
    border-radius: 30px;
    padding: 1.8rem;
    width: 35rem;

    @include media-breakpoint-down(md) {
      margin-top: 4.5rem;
      padding: .5rem;
    }

    h3 {
      color: white;
      padding-top: 0;
    }
  }
  .card {
    background: none;
    border: none;
  }

  .card-block {
    padding-top: 0;
  }

  .btn-primary {
    background: none;
    color: $white;
  }

  .view-theme-info {
    border: none;
  }
  .show-video {
    border-color: $white;
  }
}


// Main Content

.field-hauptspalte {
  h3 {
    color: $brand-primary;
  }
}

.field-rechte-spalte {

  .item__infobox {
    border: none;
    border-radius: 30px;
    box-shadow: 0 1px 15px $gray-lighter;

    ul {
      padding-left: 1.1rem;
    }

    li {
      margin-top: .6rem;
    }
  }
}

// New theme area

.block-views {
  .block-title {
    margin-bottom: 12rem;
  }
}

.view-display-id-block_2 {
  .views-view-grid {
    .row-2,
    .row-3,
    .row-4 {
      article {
        display: none;
      }
    }
  }
}



// Footer

.footer {
  background-color: $white;
  padding: $spacer*2 $grid-gutter-width/2;
  font-weight: 300;
}
